import axios from 'axios'
import _ from 'lodash'

export class RestAdapter {
    async getItems(state, filterIds = []) {
        return await (await fetch(`${state.params.apiBaseUrl}?operation=getProducts&productIds=${filterIds.join(',')}`)).json()
        // return (await axios.post(state.params.apiBaseUrl, {
        //     args: {
        //         productIds: filterIds.join(',')
        //     },
        //     operation: "getProducts"
        // })).data
    }

    async search(state) {
        let items = await (await fetch(`${state.params.apiBaseUrl}?operation=getProducts&keyword=${state.searchText}`)).json()
        items = _.compact(items)
        console.log(items)
        return { items: items }
        // return {
        //     items: (await axios.post(state.params.apiBaseUrl, {
        //         args: {
        //             keyword: state.searchText
        //         },
        //         operation: "getProducts"
        //     })).data
        // }
    }

    exportItem(item) {
        return item.id;
    }
}
